header{
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}


/* --------------CTA --------------- */

.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* --------------HEADER SOCIALS --------------- */

.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8;
    position: absolute;
    left:0;
    /* z-index: 111; */
    font-size: 2.2rem;
    bottom: 3rem;

}

.header__socials::after{
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* -------------- ME photo --------------- */


.me > img{
    /* opacity: 0.8;; */
    /* background: linear-gradient(var(--color-primary), transparent); */
    width: 32rem;
    height: 42rem;
    position: absolute;
    left: calc(50% - 18rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0 ;
    overflow: hidden;
    /* padding: 5rem 1.5rem 1.5rem 1.5rem; */
}

/* -------------- SCROLl DOWN --------------- */

.scroll__down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 200;
    font-size: 0.9rem;
}


/* --------------------------- for MEDIA QUERIES MEDIUM DEVICES -------------------- */
@media screen and (max-width:1024px){
    header{
        height: 100vh;
    }
    .me{
        
        padding-bottom: 2rem;
    }
}
/* --------------------------- for MEDIA QUERIES SMALL DEVICES -------------------- */
@media screen and (max-width:600px){

    .container {
    }

    .header{
        height: 100vh;
    }

    .header__socials, .scroll__down{
        display: none;

    }
    .me >img{
    width: 12rem;
    height: 18rem;
    position: absolute;
    left: calc(50% - 6rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0 ;
    overflow: hidden;
    }
}

@media screen and (max-width:300px){
    .me >img{
        width: 12rem;
        height: 10rem;
        position: absolute;
        left: calc(50% - 6rem);
        margin-top: 4rem;
        border-radius: 12rem 12rem 0 0 ;
        overflow: hidden;
        }
    }
